import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config'; // Import the config file
import './SingleLeaderboard.css'; // Reuse the existing CSS for styling

const EditScores = ({ leaderboardData, fetchLeaderboardData }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false); // Add loading state

    useEffect(() => {
        const preparedData = prepareTableData(leaderboardData);
        setData(preparedData);
    }, [leaderboardData]);

    const prepareTableData = (data) => {
        const getRoundDataByRoundId = (roundResults, id) => {
            if (!roundResults) return { score: 0, playingHcp: 0 }; // Return default values if roundResults is undefined
            const round = roundResults.find(result => result.roundNumber === id);
            return round ? { score: round.score, playingHcp: round.playingHcp } : { score: 0, playingHcp: 0 }; // Return default values if no round result is found
        };
        return data.map(player => ({
            name: player.name,
            R1: getRoundDataByRoundId(player.roundResults, 1),
            R2: getRoundDataByRoundId(player.roundResults, 2),
            R3: getRoundDataByRoundId(player.roundResults, 3),
        }));
    };

    const handleInputChange = (index, field, value, round) => {
        const newData = [...data];
        newData[index][round][field] = value === '' ? '' : parseFloat(value);
        setData(newData);
    };

    const handleSave = () => {
        setLoading(true); // Set loading to true before API call
        axios.put(`${config.apiBaseUrl}/save-scores`, data)
            .then(response => {
                console.log('Data saved successfully:', response.data);
                fetchLeaderboardData(); // Refetch data after saving
            })
            .catch(error => {
                console.error('Error saving data:', error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false after API call
            });
    };

    return (
        <div className="leaderboard-container">
            {loading ? (
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <>
                    <table className="leaderboard-table">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>R1 Score</th>
                            <th>R1 hcp</th>
                            <th>R2 Score</th>
                            <th>R2 hcp</th>
                            <th>R3 Score</th>
                            <th>R3 hcp</th>
                            <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((player, index) => (
                            <tr key={index}>
                                <td>{player.name}</td>
                                <td>
                                    <input
                                        type="number"
                                        className="input-small"
                                        value={player.R1.score}
                                        onChange={(e) => handleInputChange(index, 'score', e.target.value, 'R1')}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="input-small"
                                        value={player.R1.playingHcp}
                                        onChange={(e) => handleInputChange(index, 'playingHcp', e.target.value, 'R1')}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="input-small"
                                        value={player.R2.score}
                                        onChange={(e) => handleInputChange(index, 'score', e.target.value, 'R2')}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="input-small"
                                        value={player.R2.playingHcp}
                                        onChange={(e) => handleInputChange(index, 'playingHcp', e.target.value, 'R2')}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="input-small"
                                        value={player.R3.score}
                                        onChange={(e) => handleInputChange(index, 'score', e.target.value, 'R3')}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        className="input-small"
                                        value={player.R3.playingHcp}
                                        onChange={(e) => handleInputChange(index, 'playingHcp', e.target.value, 'R3')}
                                    />
                                </td>
                                <td>{(player.R1.score || 0) + (player.R2.score || 0) + (player.R3.score || 0)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <button onClick={handleSave}>Save</button>
                </>
            )}
        </div>
    );
};

export default EditScores;