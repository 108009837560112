import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import config from './config'; // Import the config file
import SingleLeaderboard from "./Components/SingleLeaderboard";
import TeamLeaderboard from "./Components/TeamLeaderboard";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import EditScores from "./Components/EditScores";
import './index.css'; // Import the CSS file

function App() {
    const token = localStorage.getItem('token');
    const [isAuthenticated, setIsAuthenticated] = useState(!!token); // Initialize based on token presence
    const [activeTab, setActiveTab] = useState(0); // Default tab index is 0
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [open, setOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const fetchLeaderboardData = useCallback(() => {
        const headers = token ? { Authorization: `Bearer ${token}` } : {};

        axios.get(`${config.apiBaseUrl}/golfers`, { headers })
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

        axios.get(`${config.apiBaseUrl}/teams`, { headers })
            .then(response => {
                setData2(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [token]);

    useEffect(() => {
        fetchLeaderboardData();
    }, [token, fetchLeaderboardData]);

    useEffect(() => {
        // Add a response interceptor
        const interceptor = axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status === 401) {
                    // Token expired or unauthorized, log out the user
                    localStorage.removeItem('token');
                    setIsAuthenticated(false);
                    setOpen(true); // Open the login dialog
                }
                return Promise.reject(error);
            }
        );

        // Cleanup the interceptor on component unmount
        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, []);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleLogin = () => {
        axios.post(`${config.apiBaseUrl}/login`, {
            username: username,
            password: password
        })
            .then(response => {
                if (response.status === 200) {
                    localStorage.setItem('token', response.data.token);
                    setIsAuthenticated(true);
                    setOpen(false);
                }
            })
            .catch(error => {
                console.error('Error during login:', error);
            });
    };

    const handleLogout = () => {
        const token = localStorage.getItem('token');
        axios.post(`${config.apiBaseUrl}/logout`, {}, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => {
                if (response.status === 200) {
                    localStorage.removeItem('token');
                    setIsAuthenticated(false);
                    setActiveTab(0); // Reset to first tab
                }
            })
            .catch(error => {
                console.error('Error during logout:', error);
            });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            {isAuthenticated ? (
                <Button variant="contained" color="secondary" onClick={handleLogout}>
                    Logout
                </Button>
            ) : (
                <Button variant="contained" onClick={handleClickOpen} sx={{
                    backgroundColor: '#004b23'
                }}>
                    Login
                </Button>
            )}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Login</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter your username and password to login.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="username"
                        label="Username"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        variant="standard"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleLogin}>Login</Button>
                </DialogActions>
            </Dialog>
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                centered
                sx={{
                    borderRadius: '8px',
                    marginBottom: '1px',
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#ffffff', // Indicator color
                    },
                }}
            >
                <Tab
                    label="Singel"
                    sx={{
                        textTransform: 'none',
                        fontWeight: 'bold',
                        fontSize: activeTab === 0 ? '20px' : '20px',
                        color: '#ffffff', // Keep text color white
                        backgroundColor: 'transparent', // No background for both active and inactive
                        borderRadius: activeTab === 0 ? '8px 8px 0 0' : '0',
                        '&.Mui-selected': {
                            color: '#ffffff', // Keep the text color white when selected
                            backgroundColor: 'transparent', // Ensure background is transparent
                        },
                        '&:hover': {
                            color: '#ffffff', // Keep hover text color white
                            backgroundColor: 'transparent', // Hover state also remains transparent
                        },
                    }}
                />
                <Tab
                    label="Lag"
                    sx={{
                        textTransform: 'none',
                        fontWeight: 'bold',
                        fontSize: activeTab === 1 ? '20px' : '20px',
                        color: '#ffffff', // Keep text color white
                        backgroundColor: 'transparent', // No background for both active and inactive
                        borderRadius: activeTab === 1 ? '8px 8px 0 0' : '0',
                        '&.Mui-selected': {
                            color: '#ffffff', // Keep the text color white when selected
                            backgroundColor: 'transparent', // Ensure background is transparent
                        },
                        '&:hover': {
                            color: '#ffffff', // Keep hover text color white
                            backgroundColor: 'transparent', // Hover state also remains transparent
                        },
                    }}
                />
                {isAuthenticated && (
                    <Tab
                        label="Editera"
                        sx={{
                            textTransform: 'none',
                            fontWeight: 'bold',
                            fontSize: activeTab === 2 ? '20px' : '20px',
                            color: '#ffffff', // Keep text color white
                            backgroundColor: 'transparent', // No background for both active and inactive
                            borderRadius: activeTab === 2 ? '8px 8px 0 0' : '0',
                            '&.Mui-selected': {
                                color: '#ffffff', // Keep the text color white when selected
                                backgroundColor: 'transparent', // Ensure background is transparent
                            },
                            '&:hover': {
                                color: '#ffffff', // Keep hover text color white
                                backgroundColor: 'transparent', // Hover state also remains transparent
                            },
                        }}
                    />
                )}
            </Tabs>

            {/* Tab content */}
            <Box className="common-container">
                {activeTab === 0 && <SingleLeaderboard leaderboardData={data} />}
                {activeTab === 1 && <TeamLeaderboard leaderboardData={data2} />}
                {activeTab === 2 && isAuthenticated && (
                    <div className="table-container">
                        <EditScores leaderboardData={data} fetchLeaderboardData={fetchLeaderboardData} />
                    </div>
                )}
            </Box>
        </Box>
    );
}

export default App;