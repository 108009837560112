import React from 'react';
import './SingleLeaderboard.css'; // Import the CSS file

const TeamLeaderboard = ({ leaderboardData }) => {
    // Function to add totalPoints to each team and sort the data
    const prepareLeaderboardData = (data) => {
        const updatedData = data.map(team => {
            const R1 = team.results?.find(result => result.roundNumber === 1)?.points || 0;
            const R2 = team.results?.find(result => result.roundNumber === 2)?.points || 0;
            const R3 = team.results?.find(result => result.roundNumber === 3)?.points || 0;

            return {
                ...team,
                pointsR1: R1,
                pointsR2: R2,
                pointsR3: R3,
                totalPoints: R1 + R2 + R3,  // Summing point values
                imageUrl: team.imageUrl // Ensure each team has an imageUrl property
            };
        });

        // Sort teams by total points descending
        return updatedData.sort((a, b) => b.totalPoints - a.totalPoints);
    };

    const preparedData = prepareLeaderboardData(leaderboardData);  // Use processed data

    return (
        <div className="leaderboard-container">
            <table className="leaderboard-table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>R1 (Pts)</th>
                    <th>R2 (Pts)</th>
                    <th>R3 (Pts)</th>
                    <th>Total Points</th>
                </tr>
                </thead>
                <tbody>
                {preparedData.map((team, index) => (  // Use preparedData here
                    <tr key={index}>
                        <td className="center-vertical">
                            <img src={process.env.PUBLIC_URL + team.imageUrl} alt={`${team.name} logo`} style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                            {team.name}
                        </td>
                        <td>{team.pointsR1}</td>
                        <td>{team.pointsR2}</td>
                        <td>{team.pointsR3}</td>
                        <td>{team.totalPoints}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default TeamLeaderboard;