import React, { useState, useEffect } from 'react';
import './SingleLeaderboard.css'; // Import the CSS file

const SingleLeaderboard = ({ leaderboardData }) => {
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        if (leaderboardData.length > 0) {
            setLoading(false); // Set loading to false when data is available
        }
    }, [leaderboardData]);

    const pointsDistribution = [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 3, 3];

    const calculatePointsForRound = (sortedScores, score, playingHcp) => {
        if (sortedScores.every(item => item.score === 0)) {
            return 0; // Return 0 points if all scores are 0
        }
        const rank = sortedScores.findIndex(item => item.score === score && item.playingHcp === playingHcp) + 1; // Find index and convert to rank (1-based)
        return pointsDistribution[rank - 1] || 0; // Safeguard with 0 if rank is out of range
    };

    const formatScore = (score) => {
        if (score === 0) return 'E';
        return score > 0 ? `+${score}` : score;
    };

    const prepareLeaderboardData = (data) => {
        const getScoreByRoundId = (roundResults, id) => {
            if (!roundResults) return null; // Return null if roundResults is undefined
            const round = roundResults.find(result => result.roundNumber === id);
            return round ? { score: round.score, playingHcp: round.playingHcp } : { score: 0, playingHcp: 0 }; // Return 0 if no round result is found
        };

        const updatedData = data.map(player => {
            const R1 = getScoreByRoundId(player.roundResults, 1);
            const R2 = getScoreByRoundId(player.roundResults, 2);
            const R3 = getScoreByRoundId(player.roundResults, 3);

            const total = (R1?.score || 0) + (R2?.score || 0) + (R3?.score || 0); // Calculate total score

            const sortedR1Scores = data.map(p => getScoreByRoundId(p.roundResults, 1)).filter(score => score !== null).sort((a, b) => a.score - b.score || a.playingHcp - b.playingHcp);
            const sortedR2Scores = data.map(p => getScoreByRoundId(p.roundResults, 2)).filter(score => score !== null).sort((a, b) => a.score - b.score || a.playingHcp - b.playingHcp);
            const sortedR3Scores = data.map(p => getScoreByRoundId(p.roundResults, 3)).filter(score => score !== null).sort((a, b) => a.score - b.score || a.playingHcp - b.playingHcp);

            return {
                ...player,
                R1: R1?.score,
                R2: R2?.score,
                R3: R3?.score,
                total, // Add total score to player object
                pointsR1: R1 ? calculatePointsForRound(sortedR1Scores, R1.score, R1.playingHcp) : 0,
                pointsR2: R2 ? calculatePointsForRound(sortedR2Scores, R2.score, R2.playingHcp) : 0,
                pointsR3: R3 ? calculatePointsForRound(sortedR3Scores, R3.score, R3.playingHcp) : 0,
                totalPoints: (R1 ? calculatePointsForRound(sortedR1Scores, R1.score, R1.playingHcp) : 0) +
                    (R2 ? calculatePointsForRound(sortedR2Scores, R2.score, R2.playingHcp) : 0) +
                    (R3 ? calculatePointsForRound(sortedR3Scores, R3.score, R3.playingHcp) : 0)
            };
        });

        return updatedData.sort((a, b) => b.totalPoints - a.totalPoints);
    };

    const getTotalClass = (total) => {
        return total < 0 ? 'total-negative' : 'total-positive';
    };

    const rankedLeaderboard = prepareLeaderboardData([...leaderboardData]); // Prepare leaderboard data with points

    return (
        <div className="leaderboard-container">
            <table className="leaderboard-table">
                <thead>
                <tr>
                    <th>Namn</th>
                    <th>R1 (Pts)</th>
                    <th>R2 (Pts)</th>
                    <th>R3 (Pts)</th>
                    <th>Agg Score</th>
                    <th>Poäng</th>
                </tr>
                </thead>
                <tbody>
                {loading ? (
                    <tr>
                        <td colSpan="6">Loading...</td>
                    </tr>
                ) : (
                    rankedLeaderboard.map((player, index) => (
                        <tr key={index}>
                            <td>{player.name}</td>
                            <td className={getTotalClass(player.R1)}>{player.R1 !== null ? `${formatScore(player.R1)} (${player.pointsR1})` : ''}</td>
                            <td className={getTotalClass(player.R2)}>{player.R2 !== null ? `${formatScore(player.R2)} (${player.pointsR2})` : ''}</td>
                            <td className={getTotalClass(player.R3)}>{player.R3 !== null ? `${formatScore(player.R3)} (${player.pointsR3})` : ''}</td>
                            <td className={getTotalClass(player.total)}>{player.total}</td>
                            <td>{player.totalPoints}</td>
                        </tr>
                    ))
                )}
                </tbody>
            </table>
        </div>
    );
};

export default SingleLeaderboard;